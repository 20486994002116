import {
  computePdcStatusForSalesCounterparty,
  computePdcStatusForStrategy,
  getDerogationReasonsByType,
} from '@/helper/pdc';
import { getPdcDerogationReasons, getPdcDetails } from '@/store/auctions';
import { getPdcModalIsDerogationOpened, openDerogation } from '@/store/ui';
import { hasPermission } from '@/store/users';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import PdcStatusIcon from '@/components/Modal/Pdc/PdcStatusIcon';
import classnames from 'classnames';
import type { OptionItem } from '@/models';
import type { PdcType, PdcTypeEnum } from '@/helper/pdc';

interface PdcDetailsRowProps {
  type: PdcType;
  typeIndex: number;
  existingPdcTypes: PdcType[];
  borderRightStyle?: string;
  rfqId?: string;
  strategyIds: (string | undefined)[];
  setDerogationReasonOptionItems: React.Dispatch<React.SetStateAction<OptionItem[]>>;
}

const PdcDetailsRow: React.FC<PdcDetailsRowProps> = ({
  type,
  typeIndex,
  existingPdcTypes,
  borderRightStyle,
  rfqId,
  strategyIds,
  setDerogationReasonOptionItems,
}) => {
  const dispatch = useDispatch();
  const pdcDetails = useSelector(getPdcDetails(rfqId));
  const haveDerogatePermission = useSelector(hasPermission('PERFORM_PDC_DEROGATION'));
  const pdcDerogationEnabled = useToggleFeature('PdcDerogation');
  const isDerogationOpened = useSelector(getPdcModalIsDerogationOpened());
  const derogationReasons = useSelector(getPdcDerogationReasons());

  const onPdcStatusClicked = useCallback(
    (
      legIndex: number,
      typeId: PdcTypeEnum,
      typeLabel: string | undefined | null,
      pdcUuid: string | undefined,
      message: string | undefined,
      derogationReason: string | undefined,
      isEligible: boolean | undefined = false,
    ) => {
      if (derogationReasons !== undefined && typeId !== null && typeId !== undefined) {
        const options =
          getDerogationReasonsByType(derogationReasons, typeId)?.map(reason => {
            return {
              value: reason.type,
              label: reason.message,
              translationKey: undefined,
            } as OptionItem;
          }) ?? [];
        setDerogationReasonOptionItems(options);
      }
      dispatch(
        openDerogation({
          legIndex: legIndex,
          typeId: typeId ?? '',
          typeLabel: typeLabel ?? '',
          isEligible: isEligible ?? false,
          pdcUuid: pdcUuid ?? '',
          message: message,
          derogationReason: derogationReason,
        }),
      );
    },
    [derogationReasons, dispatch, setDerogationReasonOptionItems],
  );

  return (
    <tr key={type.id} className={classnames({ 'border-bottom': typeIndex !== existingPdcTypes.length - 1 })}>
      <td>
        <div>{type.label}</div>
      </td>
      <td style={{ borderRight: borderRightStyle }}>
        <div className="d-flex justify-content-center">
          <PdcStatusIcon
            uiColor={computePdcStatusForSalesCounterparty(type, pdcDetails?.transaction?.pdcs, strategyIds)}
          />
        </div>
      </td>
      {strategyIds.map((strategyId, strategyIndex) => {
        const pdcsOfStrategy = pdcDetails?.transaction?.pdcs?.filter(
          pdc => pdc.strategyUuid === strategyId && pdc.level === 'ALLOCATION',
        );
        const legIds = (
          pdcsOfStrategy?.map(pdc => pdc.legUuid).filter((value, index, self) => self.indexOf(value) === index) ?? []
        ).filter(id => id !== undefined && id !== '');
        return (
          <>
            <td>
              <div className="d-flex justify-content-center">
                <PdcStatusIcon uiColor={computePdcStatusForStrategy(type, pdcsOfStrategy)} />
              </div>
            </td>
            {legIds.map((value, legIndex) => {
              const pdc = pdcsOfStrategy?.find(pdc => pdc.type === type.id && pdc.legUuid === value);
              return (
                <td
                  key={value}
                  style={{
                    borderRight:
                      legIndex === legIds.length - 1 && strategyIndex !== strategyIds.length - 1
                        ? borderRightStyle
                        : '',
                  }}
                >
                  <div className="d-flex justify-content-center">
                    {pdc?.isDerogable === true &&
                    haveDerogatePermission &&
                    pdcDerogationEnabled &&
                    !isDerogationOpened ? (
                      <button
                        className="btn btn-flat-secondary btn-sm visible"
                        onClick={() =>
                          onPdcStatusClicked(
                            legIndex + 1,
                            type.id,
                            type.label,
                            pdc?.uuid,
                            pdc.message,
                            pdc.pdcDerogation,
                            pdc?.eligible,
                          )
                        }
                      >
                        <PdcStatusIcon uiColor={pdc?.uiColor} />
                      </button>
                    ) : (
                      <PdcStatusIcon uiColor={pdc?.uiColor} />
                    )}
                  </div>
                </td>
              );
            })}
          </>
        );
      })}
    </tr>
  );
};

export default PdcDetailsRow;
